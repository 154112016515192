
import * as PropTypes from 'prop-types'

import React, { Component } from 'react'
import { autobind } from 'core-decorators'
import classNames from 'classnames'
import { filter } from '../../../shared/obj'
import { eventHandlers } from '../../../shared/react-helpers'
import Icon from '../../../shared/icons'
import { genevaCssClass } from '../../../shared/utils'

const CM_IMG_ALLOWED_ATTRS = [
  'alt',
  'title',
  'height',
  'width',
  'sizes',
  'src',
  'srcSet',
  'type',
  'useMap',
  'tabIndex'
]

const IMG_ALLOWED_ATTRS = [
  'itemtype',
  'itemscope',
  'itemprop',
  'loading'
]

const FILE_ALLOWED_ATTRS = ['alt', 'type', 'href', 'tabIndex']

const VIDEO_ALLOWED_ATTRS = [
  // TODO
]

const WEBP_ALLOWED_ATTRS = [
  'id',
  'style',
  'className',
  'height',
  'width',
  'sizes',
  'src',
  'srcSet',
  'type',
  'useMap',
  'tabIndex',
  'itemtype',
  'itemscope',
  'itemprop'
]

const numberOrAuto = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.oneOf(['auto']),
])

const boolOrString = PropTypes.oneOfType([PropTypes.bool, PropTypes.string])


export const defaultAttributes = {
  editable: 'image',
  type: '',
  minWidth: null,
  minHeight: null,
  mediaType: null,
  tagName: 'img',
  linkable: false,
  spec: {},
  replace: false,
}

export const getDataAttributeValue = (obj, key) => obj[key] || defaultAttributes[key]

function getSizeValue(value) {
  return value === 'auto' ? null : value
}

export default class Media extends Component {
  static propTypes = {
    // required properties
    pid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    spec: PropTypes.object.isRequired,

    // optional properties
    className: PropTypes.string,
    tagName: PropTypes.string,
    mediaType: PropTypes.oneOf(['image', 'video', 'file']),
    minWidth: numberOrAuto,
    minHeight: numberOrAuto,
    aspectRatio: PropTypes.number,
    linkable: boolOrString,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    webpValue: PropTypes.string,
    webpVariants: PropTypes.array,
    style: PropTypes.object,
    multiple: PropTypes.bool,

    env: PropTypes.object,

    file: PropTypes.object,

    attrs: PropTypes.object,
    css: PropTypes.object,

    // for internal use
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    renderItem: PropTypes.func,

    sizes: PropTypes.shape({
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
  };

  static defaultProps = {
    css: {},
    attrs: {},
  };

  constructor(props) {
    super(props)

    this.state = {
      videoHeight: props.spec.defaultHeight,
      videoWidth: props.spec.defaultWidth,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.handleChange({
        target: this.mediaRef,
      })
    }
  }

  getDataAttributes(spec) {
    spec = spec || this.props
    return Object.keys(defaultAttributes).reduce((memo, key) => {
      const value = this.getDataAttribute(key)

      if (value) {
        memo[key] = value
      }

      return memo
    }, {})
  }

  getDataAttribute(key, spec) {
    let result = getDataAttributeValue(spec || {}, key)
    if (!result) {
      result = getDataAttributeValue(this.props || {}, key)
    }
    if (!result) {
      result = getDataAttributeValue(this.props.spec || {}, key)
    }
    return result
  }

  getConstraints() {
    const { props } = this
    const spec = props.spec || {}

    return {
      minWidth: getSizeValue(props.minWidth || spec.minWidth),
      minHeight: getSizeValue(props.minHeight || spec.minHeight),
      aspectRatio: getSizeValue(props.aspectRatio || spec.aspectRatio),
      useCropperSize: spec.useCropperSize
    }
  }

  getMimeType() {
    const url = this.mediaRef.src || this.mediaRef.href
    return url ? url.split('.').pop().split('?').shift() : null
  }

  /**
   * TODO: Thi sshould probably moved up one level to the connector. That way
   * the mechanism to create the event is in one place with the value extraction
   * and it can be made sure that value is always the same.
   *
   */
  getEventContext(/* event */) {
    // event.target.src.indexOf('no-image') >= 0
    // ? null
    // : event.target.src,

    const type = this.getMediaType()

    return {
      target: {
        value: this.props.value,
        id: this.props.id,
        type,
        pid: this.getDataAttribute('pid'),
        mimeType: this.getMimeType(),
        constraints: this.getConstraints(),
        file: this.props.file,
        multiple: this.getDataAttribute('multiple'),
      },
    }
  }

  getSrc() {
    // TODO: determine file type
    return (
      this.props.value || (this.props.env.CM ? '/images/no-image.svg' : '')
    )
  }

  getAllowedAttribsForElement(Element) {
    switch (Element) {
      case 'img':
        return this.props.env.CM
          ? CM_IMG_ALLOWED_ATTRS
          : CM_IMG_ALLOWED_ATTRS.concat(IMG_ALLOWED_ATTRS)
      case 'video':
        return VIDEO_ALLOWED_ATTRS
      default:
        return FILE_ALLOWED_ATTRS
    }
  }

  getMediaType() {
    if (this.props.id) {
      return 'video'
    }

    if (this.props.value) {
      return 'image'
    }

    if (this.type) {
      return this.type
    }

    if (this.props.mediaType) {
      return this.props.mediaType
    }

    return 'image'
  }

  @autobind
  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(this.getEventContext(event))
    }
  }

  @autobind
  handleFocus(event) {
    if (this.props.onFocus) {
      this.props.onFocus(this.getEventContext(event))
    }
  }

  @autobind
  handleBlur(event) {
    if (this.props.onBlur) {
      this.props.onBlur(this.getEventContext(event))
    }
  }

  @autobind
  handleResize() {
    // Resize the filled video placeholder to match the aspectRatio from the template
    if (this.subElemRef) {
      const clientWidth = this.subElemRef.clientWidth

      if (clientWidth !== this.state.videoWidth) {
        // Calculate new height of the video element
        const ratio = this.props.spec.videoSizeRatio || 1
        const newHeight = clientWidth / ratio

        this.setState({
          videoHeight: newHeight,
          videoWidth: clientWidth,
        })
      }
    }
  }


  focus(type) {
    if (type) {
      this.type = type
    }
    if (this.mediaRef) {
      const event = document.createEvent('FocusEvent')
      event.initUIEvent('focus', true, true, window, 1)
      this.mediaRef.dispatchEvent(event)
    }
  }

  renderVideoElemSubContent() {
    return (
      <div
        className="geneva-video grid-block vertical"
        id="subVideoElem"
        style={{
          height: this.state.videoHeight,
        }}
        ref={(ref) => {
          this.subElemRef = ref

          // This will ensure a resizing of the video element
          // after it is mounted AND the css is loaded
          setTimeout(() => {
            this.handleResize()
          }, 100)
        }}
      >
        <Icon name="ion-play" />
        <span>{this.props.value}</span>
      </div>
    )
  }

  @autobind
  renderVideoElem(mediaProps, handlers) {
    const env = this.props.env.CM || this.props.env.PM

    return (
      <div
        {...mediaProps}
        src={undefined}
        className={classNames(
          'grid-block',
          mediaProps.className,
          'video-placeholder'
        )}
        data-videopath={mediaProps.src}
        data-videoid={mediaProps.id}
        ref={ref => (this.mediaRef = ref)}
        {...handlers}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        {this.props.value && env ? this.renderVideoElemSubContent() : null}
      </div>
    )
  }

  renderWebP(mediaProps, handlers) {
    return (
      <picture>
        {this.renderSourceElements(mediaProps, handlers)}
        <img
          alt=""
          {...mediaProps}
          {...handlers}
          draggable={false}
          src={mediaProps.src}
        />
      </picture>
    )
  }

  renderSourceElements(mediaProps, handlers) {
    // Remove not allowed attributes für <source> element
    const filterMediaProps = filter(mediaProps, WEBP_ALLOWED_ATTRS)

    if (Array.isArray(this.props.webpVariants) && this.props.webpVariants.length > 0) {
      return this.props.webpVariants.map((source, index) => {
        let url = ''
        const urlParts = []

        if (source.variants) {
          Object.keys(source.variants).forEach((key) => {
            const variantUrl = source.variants[key]
            urlParts.push(`${variantUrl} ${key}`)
          })

          url = urlParts.join(', ')
        }

        let sizes = null
        if (source.sizes) {
          sizes = source.sizes.join(', ')
        }

        return (<source
          key={index}
          {...filterMediaProps}
          {...handlers}
          draggable={false}
          src={null}
          srcset={url}
          media={source.media}
          sizes={sizes}
          type="image/webp"
        />)
      })
    }

    return (<source
      {...filterMediaProps}
      {...handlers}
      draggable={false}
      src={null}
      srcset={this.props.webpValue}
      type="image/webp"
    />)
  }

  render() {
    const sizes = this.props.sizes || {}
    const attrs = this.props.attrs || {}
    const hasChildren = this.props.renderItem
    // const { mergedProps } = Object.assign({}, spec, this.props)
    const Elem = this.props.tagName || (hasChildren ? 'div' : 'img')

    // const dataAttributes = this.getDataAttributes(mergedProps)

    const src = this.getSrc()
    const mediaType = this.getMediaType()

    const dataAttribs = Object.keys(this.props).reduce((memo, key) => {
      // Allow all data and aria properties
      if (key.indexOf('data') === 0 || key.indexOf('aria') === 0) {
        memo[key] = this.props[key]
      }
      return memo
    }, {})

    const mediaProps = {
      // TODO validate data attributes not necessary
      // ...dataAttributes,

      ...sizes,
      className: classNames(
        this.props.className,
        this.props.css.geneva_media,
        this.props.justButton || this.props.value
          ? null
          : this.props.css.empty_media,
        genevaCssClass('media', mediaType)
      ),

      style: {
        ...this.props.style,
        ...sizes,
      },

      ...dataAttribs,
      ...filter(attrs, this.getAllowedAttribsForElement(Elem)),
      ...filter(this.props, this.getAllowedAttribsForElement(Elem)),

      src,
      id: this.props.id,
    }

    const handlers = eventHandlers(this.props)
    const children
      = this.props.value && hasChildren
        ? this.props.renderItem(mediaProps)
        : null

    if (mediaType === 'video') {
      return this.renderVideoElem(mediaProps, handlers)
    }

    if (!(this.props.env.CM || this.props.env.PM) && this.props.webpValue) {
      return this.renderWebP(mediaProps, handlers)
    }
    return (
      <Elem
        {...mediaProps}
        ref={ref => (this.mediaRef = ref)}
        {...handlers}
        draggable={false}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      >
        {children}
      </Elem>
    )
  }
}
